import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import './Products.css'; // Import the CSS file
import CouponContext from './CouponContext';
const SelectRewards = ({ columns, data }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const matchingRows = data.filter(row =>
            couponData.reward_upc.includes(row.upc_guid)
        );
        setSelectedRows(matchingRows);
    }, []);

    useEffect(() => {
        const selectedUPCs = selectedRows?.map((each) => each.upc_guid) || [];
        updateCouponData({ reward_upc: selectedUPCs });
    }, [selectedRows]);

    const handleSearch = useCallback((event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    }, []);

    const handleClearSearch = useCallback(() => {
        setSearchTerm('');
    }, []);

    const handlePageChange = useCallback((pageNumber) => {
        setCurrentPage(pageNumber);
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(1);
    }, []);

    const handleRowSelection = useCallback((row) => {
        setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.includes(row);
            return isSelected
                ? prevSelectedRows.filter((selectedRow) => selectedRow !== row)
                : [...prevSelectedRows, row];
        });

    }, []);

    const handleSelectAll = useCallback(() => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.length === data.length ? [] : data
        );
    }, [data]);

    const filteredData = useMemo(() =>
        data.filter((row) => row.product_name.includes(searchTerm.toUpperCase())),
        [data, searchTerm]
    );

    const paginatedData = useMemo(() =>
        filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
        [filteredData, currentPage, rowsPerPage]
    );

    return (
        <>
            <div>
                <div className="Steps_Header d-flex align-items-center justify-content-between">
                    <h5>Select Rewards</h5>
                    <div className="Search_Box">
                        <input
                            type="text"
                            placeholder="Search Rewards..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <span className="Input_Icon" onClick={handleClearSearch} title="Clear search">
                            <svg width="17" height="18" viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L16 17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16 1L1 17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="table-responsive Create_Coupons_Table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.length === data.length}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                {columns.map((column) => (
                                    <th key={column.accessor}>{column.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(row)}
                                            onChange={() => handleRowSelection(row)}
                                        />
                                    </td>
                                    {columns.map((column) => (
                                        <td key={column.accessor}>{row[column.selector]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-controls">
                        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <div>
                            {Array.from(
                                { length: Math.ceil(filteredData.length / rowsPerPage) },
                                (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        disabled={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectRewards;