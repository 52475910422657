import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DataTable from "react-data-table-component";
import ls, { set } from 'local-storage';
import { getBrand } from '../../API/Wholesalerapi';
import CouponContext from './CouponContext';
import CouponSummary from './CouponSummary';
import scissors from "../../Assets/images/scissors.png";
import SelectProducts from './SelectProducts';
import SelectRewards from './SelectRewards';
const Loader = () => <div id="cover-spin"></div>;

const BrandProducts = ({ couponType, provider }) => {
    const { couponData, updateCouponData } = useContext(CouponContext);
    const [searchReward, setSearchReward] = useState("");
    const [upcList, setUpcList] = useState([]);
    const [filteredUPC, setFilteredUPC] = useState([]);
    const [filteredRewards, setFilteredRewards] = useState([]);
    const [spinloading, setSpinLoading] = useState(false);
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";

    const fetchUPCs = useCallback(async () => {
        setSpinLoading(true);
        const response = await getBrand(selectedbrand[0].guid);
        const updatedUPCList = response.data.Message.upc.map(upc => ({
            ...upc,
            brand_name: response.data.Message?.brand_name,
            is_store_label: upc.is_store_label ? "Yes" : "No"
        }));
        setUpcList(updatedUPCList);
        setSpinLoading(false);
    }, [selectedbrand]);

    useEffect(() => {
        if (provider === 'brand') {
            fetchUPCs();
        }
    }, []);

    const columns = useMemo(
        () => [
            {
                name: "BRAND",
                selector: "brand_name",
            },
            {
                name: "UPC",
                selector: "upc11",
                sortable: true
            },
            {
                name: "STORE LABEL",
                selector: "is_store_label",
                sortable: true
            },
            {
                name: "PRODUCT NAME",
                selector: "product_name",
            },
            {
                name: "CATEGORY",
                selector: "category_name",
                sortable: true
            }
        ],
        [],
    );
    return (
        <React.Fragment>
            {spinloading && <Loader />}
            <div className="tab-pane fade show active" id="nav-step-1" role="tabpanel" aria-labelledby="nav-step-1-tab">
                <div className="row g-3">
                    <div className="col-xxl-9 col-xl-8 col-12">
                        <div className="table-responsive Create_Coupons_Table">
                            {!spinloading && upcList?.length > 0 && (
                                <SelectProducts
                                    columns={columns}
                                    data={upcList}
                                />
                            )}
                        </div>
                        <br />
                        {(couponData.offer_type === 3 || couponData.offer_type === 4) && (
                            <div className="table-responsive Create_Coupons_Table">
                                {!spinloading && upcList?.length > 0 && (
                                    <SelectRewards
                                        columns={columns}
                                        data={upcList}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-12">
                        <CouponSummary couponType={couponType} />
                        {couponType === "coupon" && (
                            <div className="Common_Block mt-21">
                                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                    <h5 className="">Coupon Preview</h5>
                                </div>
                                <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
                                    <div className="Coupons_Card text-center">
                                        <img
                                            src={scissors}
                                            className="img-fluid scissors"
                                            alt="Products"
                                        />
                                        <img
                                            src={couponData.image_1}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <h2 className="Price">
                                            {couponData.title}
                                            <span></span>
                                        </h2>
                                        <p>{couponData.description}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BrandProducts;
